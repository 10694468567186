import React from "react";

import "./index.css";

const readingPlan2025Updated =
  "https://www.dropbox.com/scl/fi/mhs2rftkdhf44oz9gcwjd/2025-WAC-Reading-Plan-updated-Feb-26th.pdf?rlkey=ro2qoyxmry6b32e75iu7yhccg&st=32egvbxn&dl=1";

function ReadingPlanForTheYear() {
  return (
    <div className="DownloadReadingPlan__component">
      <a
        href={readingPlan2025Updated}
        download="wearechurchreadingPDF-2025"
        rel="noopener noreferrer"
      >
        <h3>2025 Reading Plan PDF</h3>
      </a>
      <br />
    </div>
  );
}

export default ReadingPlanForTheYear;
